"use client";

import * as React from "react";
import * as AvatarPrimitive from "@radix-ui/react-avatar";

import { cn } from "~/utils/cn";
import { enableCorsOnImageResponseCache, isSafari } from "./avatar-base.utils";

const Avatar = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Root>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Root
    ref={ref}
    className={cn(
      "relative flex h-10 w-10 shrink-0 overflow-hidden rounded-full",
      className,
    )}
    {...props}
  />
));
Avatar.displayName = AvatarPrimitive.Root.displayName;

const AvatarImage = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Image>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Image>
>(({ className, crossOrigin = "anonymous", ...props }, ref) => {
  const [firstSrcLoad, setFirstSrcLoad] = React.useState(false);

  React.useEffect(() => {
    if (props.src && crossOrigin === "anonymous") {
      const onLoadFinish = () => {
        setFirstSrcLoad(true);
      };

      setFirstSrcLoad(false);
      enableCorsOnImageResponseCache(props.src, onLoadFinish);
    }
  }, [props.src, crossOrigin]);
  const crossOriginProp = isSafari() ? {} : { crossOrigin };
  return (
    firstSrcLoad && (
      <AvatarPrimitive.Image
        ref={ref}
        className={cn("aspect-square h-full w-full", className)}
        {...crossOriginProp}
        {...props}
      />
    )
  );
});
AvatarImage.displayName = AvatarPrimitive.Image.displayName;

const AvatarFallback = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Fallback>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Fallback>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Fallback
    ref={ref}
    className={cn(
      "flex h-full w-full items-center justify-center rounded-full bg-gray-200",
      className,
    )}
    {...props}
  />
));
AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName;

const AvatarAction: React.FC<
  React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>
> = ({ children, className }) => {
  return (
    <div
      className={cn(
        "absolute bottom-0 right-0 flex h-5 w-5 items-center justify-center rounded-full bg-[#F1F2F5] p-[2px]",
        className,
      )}
    >
      {children}
    </div>
  );
};

const AvatarContainer: React.FC<
  React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>
> = ({ children, className }) => {
  return (
    <div
      className={cn("relative inline w-fit", className)}
      role="region"
      aria-label="avatar"
    >
      {children}
    </div>
  );
};

const AvatarIcon: React.FC<
  React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>
> = ({ children, className }) => {
  return (
    <div
      className={cn(
        "rouded-xl flex h-full w-full items-center justify-center rounded-full bg-background-muted",
        className,
      )}
    >
      {children}
    </div>
  );
};

export {
  Avatar as AvatarBase,
  AvatarImage,
  AvatarFallback,
  AvatarAction,
  AvatarContainer,
  AvatarIcon,
};
