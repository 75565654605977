export const AVATAR_GROUP_COLORS = {
  violet: "#EFE5FF",
  pink: "#FFE5F4",
  cyan: "#CCF0FF",
  teal: "#CCFFFE",
  lime: "#CCFFDA",
  yellow: "#EAFFCC",
};

export const AVATAR_GROUP_COLORS_ARRAY = [
  AVATAR_GROUP_COLORS.yellow,
  AVATAR_GROUP_COLORS.lime,
  AVATAR_GROUP_COLORS.teal,
  AVATAR_GROUP_COLORS.cyan,
  AVATAR_GROUP_COLORS.pink,
  AVATAR_GROUP_COLORS.violet,
];

export const MAX_AVATARS = 5;

export const MIN_AVATARS = 1;
